import React, { FC } from "react";
import {
  GlobalStyle,
  targetBaseFontSizePx,
  colors,
  spaceRem,
  fontStacks,
} from "../global-style";
import MailchimpSubscribe from "react-mailchimp-subscribe";

interface PopupProps {
  onClose: () => void;
  currentLanguage: string;
  remInPx: number;
}

const Popup: FC<PopupProps> = ({ onClose, currentLanguage, remInPx }) => {
  return (
    <div className="popup">
      <button className="close-btn" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
      <h3
        style={{
          textTransform: "uppercase",
          fontSize: "0.65rem",
          fontWeight: 600,
          color: colors.mediumBlue,
          letterSpacing: 2,
          marginBottom: `${0.25 * spaceRem}rem`,
        }}
      >
        {(currentLanguage === "en" && "Newsletter") ||
          (currentLanguage === "nl" && "Nieuwsbrief")}
      </h3>
      <h2
        style={{
          color: colors.white,
          marginBottom: `${1 * spaceRem}rem`,
        }}
      >
        {(currentLanguage === "en" && "Stay up to date") ||
          (currentLanguage === "nl" && "Updates in je inbox")}
      </h2>

      <a
        href="https://msg-sustainable-strategies.email-provider.eu/memberforms/subscribe/standalone/form/?a=rq4ucopfol&l=by4ogsvw0h"
        target="_blank"
        className="button"
        onClick={onClose}
        style={{
          display: "block",
          marginTop: `${0.25 * spaceRem}rem`,
          marginLeft: "auto",
          marginRight: "auto",
          flexGrow: 1,
        }}
      >
        {(currentLanguage === "en" && "Subscribe") ||
          (currentLanguage === "nl" && "Aanmelden")}
      </a>
    </div>
  );
};

export default Popup;
