import React, { FC, useState, useEffect, useContext } from "react";
import Headroom from "react-headroom";
import { spaceRem, colors, fontStacks } from "../global-style";
import { Logo } from "./logo";
import { useViewportScroll, motion } from "framer-motion";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql, Link } from "gatsby";
import { NavbarNarrowQuery, MenuItemFragment } from "../../graphql-types";
import { LanguageSwitch } from "./language-switch";
import { LayoutContext } from "./layout";

export const NavbarNarrow: FC = () => {
  const { innerHeightPx, setSearchIsVisible } = useContext(LayoutContext);
  const data = useStaticQuery<NavbarNarrowQuery>(graphql`
    fragment ConnectedNode on WpMenuItem {
      connectedNode {
        node {
          uri
        }
      }
    }

    fragment MenuItem on WpMenuItem {
      label
      ...ConnectedNode
      childItems {
        nodes {
          label
          ...ConnectedNode
        }
      }
    }

    query NavbarNarrow {
      nlPrimary: allWpMenuItem(
        filter: {
          locations: { eq: PRIMARY_NAVIGATION }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      enPrimary: allWpMenuItem(
        filter: {
          locations: { eq: PRIMARY_NAVIGATION___EN }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      nlSecondary: allWpMenuItem(
        filter: {
          locations: { eq: SECONDARY_NAVIGATION }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      enSecondary: allWpMenuItem(
        filter: {
          locations: { eq: SECONDARY_NAVIGATION___EN }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }
    }
  `);

  const [isExpanded, setIsExpanded] = useState(false);
  const [currentSubmenu, setCurrentSubMenu] = useState<MenuItemFragment>();
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const location = useLocation();
  const currentLanguage = location.pathname.split("/").filter(v => v)[0] as
    | "nl"
    | "en";
  const primaryMenuItems =
    data[`${currentLanguage}Primary` as "nlPrimary" | "enPrimary"]?.edges;
  const secondaryMenuItems =
    data[`${currentLanguage}Secondary` as "nlSecondary" | "enSecondary"]?.edges;

  const { scrollY } = useViewportScroll();

  useEffect(() => {
    setUserHasScrolled(typeof window !== "undefined" && window.scrollY > 20);

    scrollY.onChange(v => {
      setUserHasScrolled(v > 20);
    });
  }, []);

  useEffect(() => {
    setIsExpanded(false);
    setCurrentSubMenu(undefined);
  }, [location.pathname]);

  return (
    <Headroom
      wrapperStyle={{
        zIndex: 999,
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
      }}
      pinStart={100}
      disable={isExpanded}
    >
      <div
        style={{
          ...(isExpanded && {
            height: innerHeightPx || "100vh",
            backgroundColor: colors.darkestBlue,
          }),
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <motion.div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: `${0.5 * spaceRem}rem`,
            backgroundColor:
              !isExpanded && userHasScrolled ? colors.white : "transparent",
            boxShadow:
              !isExpanded && userHasScrolled
                ? "0 5px 15px rgba(0,0,0,0.05)"
                : "none",
          }}
          initial={false}
          animate={{
            color: userHasScrolled ? colors.mediumBlue : colors.white,
          }}
        >
          {currentSubmenu && (
            <button
              style={{ backgroundColor: "transparent", fontSize: "1.25rem" }}
              onClick={() => {
                setCurrentSubMenu(undefined);
              }}
            >
              &larr;
            </button>
          )}

          <motion.div
            style={{ height: `${2 * spaceRem}rem` }}
            animate={{ opacity: isExpanded ? 0 : 1 }}
          >
            <Link to={`/${currentLanguage}/home/`}>
              <Logo />
            </Link>
          </motion.div>

          <div style={{ display: "flex" }}>
            <motion.button
              initial={false}
              animate={{
                backgroundColor: colors.white,
              }}
              style={{
                height: `${2 * spaceRem}rem`,
                width: `${2 * spaceRem}rem`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                outline: "none",
                cursor: "pointer",
                marginRight: "0.75rem",
              }}
              onClick={() => {
                setSearchIsVisible(true);
              }}
            >
              <svg viewBox="0 0 21.707 21.707" width="60%">
                <g fill="none" stroke="#000" strokeWidth="2">
                  <path d="M15.58 15.581L20.999 21" />
                  <g>
                    <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
                    <circle cx="9.5" cy="9.5" r="8.5" />
                  </g>
                </g>
              </svg>
            </motion.button>

            <motion.button
              initial={false}
              animate={{
                backgroundColor: isExpanded
                  ? colors.mediumBlue
                  : colors.darkestBlue,
              }}
              style={{
                height: `${2 * spaceRem}rem`,
                width: `${2 * spaceRem}rem`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                outline: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentSubMenu(undefined);
                setIsExpanded(!isExpanded);
              }}
            >
              <svg width={26.054} height={19.509} viewBox="0 0 26.054 19.509">
                <motion.g
                  fill={isExpanded ? colors.white : colors.mediumBlue}
                  stroke={isExpanded ? colors.white : colors.mediumBlue}
                >
                  <path d="M25.55 14.578v1.3a6.217 6.217 0 00-1.473-.18 9.515 9.515 0 00-4.656 1.574 8.9 8.9 0 01-10.548 0 9.556 9.556 0 00-4.667-1.574 7.909 7.909 0 00-3.711 1.068v-1.417a8.377 8.377 0 013.711-.922 10.65 10.65 0 015.274 1.732 7.687 7.687 0 009.322 0 10.65 10.65 0 015.274-1.732 7.788 7.788 0 011.474.151z" />
                  <path d="M25.55 7.612v1.3a6.217 6.217 0 00-1.473-.18 9.515 9.515 0 00-4.656 1.574 8.9 8.9 0 01-10.548 0 9.556 9.556 0 00-4.667-1.574A7.909 7.909 0 00.495 9.8V8.383a8.377 8.377 0 013.711-.922A10.65 10.65 0 019.48 9.193a7.687 7.687 0 009.322 0 10.65 10.65 0 015.274-1.732 7.788 7.788 0 011.474.151z" />
                  <path d="M25.55.646v1.3a6.217 6.217 0 00-1.473-.18 9.515 9.515 0 00-4.656 1.574 8.9 8.9 0 01-10.548 0 9.556 9.556 0 00-4.667-1.574A7.909 7.909 0 00.495 2.834V1.417A8.377 8.377 0 014.206.495 10.65 10.65 0 019.48 2.227a7.687 7.687 0 009.322 0A10.65 10.65 0 0124.076.495a7.788 7.788 0 011.474.151z" />
                </motion.g>
              </svg>
            </motion.button>
          </div>
        </motion.div>

        {isExpanded && (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              color: colors.white,
            }}
          >
            {!currentSubmenu && (
              <>
                <div
                  style={{
                    marginLeft: `${1 * spaceRem}rem`,
                    paddingLeft: `${0.5 * spaceRem}rem`,
                    paddingRight: `${1 * spaceRem}rem`,
                    borderLeft: `1px solid ${colors.white}`,
                    marginBottom: `${0.5 * spaceRem}rem`,
                  }}
                >
                  <ul>
                    {primaryMenuItems?.map((edge, i) => (
                      <li
                        key={i}
                        style={{
                          fontFamily: fontStacks.poppins,
                          fontSize: "1.1rem",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          to={edge?.node?.connectedNode?.node?.uri}
                          style={{
                            padding: `${0.25 * spaceRem}rem`,
                            display: "block",
                          }}
                        >
                          {edge?.node?.label}
                        </Link>

                        {edge?.node?.childItems?.nodes?.length ? (
                          <button
                            style={{
                              backgroundColor: "transparent",
                              fontSize: "1.25rem",
                              color: colors.mediumBlue,
                              padding: `${0.25 * spaceRem}rem`,
                            }}
                            onClick={() => {
                              setCurrentSubMenu(edge.node as MenuItemFragment);
                            }}
                          >
                            +
                          </button>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  style={{
                    marginLeft: `${1 * spaceRem}rem`,
                    paddingLeft: `${0.5 * spaceRem}rem`,
                    marginBottom: `${0.5 * spaceRem}rem`,
                  }}
                >
                  <ul>
                    {secondaryMenuItems?.map((edge, i) => (
                      <li key={i}>
                        <Link
                          to={edge?.node?.connectedNode?.node?.uri}
                          style={{
                            padding: `${0.25 * spaceRem}rem`,
                            display: "block",
                          }}
                        >
                          {edge?.node?.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  style={{
                    marginLeft: `${1 * spaceRem}rem`,
                    paddingLeft: `${0.75 * spaceRem}rem`,
                  }}
                >
                  <LanguageSwitch />
                </div>
              </>
            )}

            {currentSubmenu && (
              <div
                style={{
                  marginLeft: `${1 * spaceRem}rem`,
                  paddingLeft: `${0.5 * spaceRem}rem`,
                  paddingRight: `${1 * spaceRem}rem`,
                  marginBottom: `${0.5 * spaceRem}rem`,
                }}
              >
                <h3
                  style={{
                    textTransform: "uppercase",
                    fontSize: "0.65rem",
                    fontWeight: 600,
                    color: colors.mediumBlue,
                    letterSpacing: 2,
                    padding: `${0.25 * spaceRem}rem`,
                  }}
                >
                  {currentSubmenu.label}
                </h3>

                <ul>
                  {currentSubmenu.childItems?.nodes?.map((node, i) => (
                    <li
                      key={i}
                      style={{
                        fontFamily: fontStacks.poppins,
                        fontSize: "1.1rem",
                      }}
                    >
                      <Link
                        to={node?.connectedNode?.node?.uri}
                        style={{
                          padding: `${0.25 * spaceRem}rem`,
                          display: "block",
                        }}
                      >
                        {node?.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div
              style={{
                color: colors.mediumBlue,
                height: `${2.5 * spaceRem}rem`,
                padding: `${0.5 * spaceRem}rem`,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: `${0.5 * spaceRem}rem`,
              }}
            >
              <Link to={`/${currentLanguage}/home/`}>
                <Logo />
              </Link>
            </div>
          </div>
        )}
      </div>
    </Headroom>
  );
};
