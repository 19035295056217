import React, { FC, Fragment } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { LanguageSwitchQuery } from "../../graphql-types";
import { spaceRem, colors, fontStacks } from "../global-style";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";

export const LanguageSwitch: FC<{
  linkColor?: string;
}> = props => {
  const data = useStaticQuery<LanguageSwitchQuery>(graphql`
    query LanguageSwitch {
      wp {
        languages {
          code
        }
      }

      allWpContentNode {
        edges {
          node {
            uri

            ... on WpPage {
              language {
                code
              }
              translations {
                language {
                  code
                }
                uri
              }
            }

            ... on WpProgrammeItem {
              language {
                code
              }
              translations {
                language {
                  code
                }
                uri
              }
            }
          }
        }
      }
    }
  `);

  const location = useLocation();

  const contentNode = data.allWpContentNode?.edges?.find(edge => {
    return edge?.node?.uri === location.pathname;
  })?.node;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {data.wp.languages?.map((language, i) => (
        <Fragment key={i}>
          {i > 0 && (
            <div
              style={{
                display: "inline-block",
                marginLeft: `${0.25 * spaceRem}rem`,
                marginRight: `${0.25 * spaceRem}rem`,
                backgroundColor: colors.darkBlue,
                opacity: 0.3,
                width: 1,
                height: `${1.25 * spaceRem}rem`,
                transform: "rotate(20deg)",
              }}
            />
          )}

          <div
            style={{
              fontSize: "0.8rem",
              color: colors.darkBlue,
              fontFamily: fontStacks.roboto,
              fontWeight: 600,
            }}
          >
            {(() => {
              const translation =
                contentNode &&
                "language" in contentNode &&
                contentNode.language?.code !== language?.code &&
                contentNode.translations?.find(
                  translation => translation?.language?.code === language?.code
                );

              if (translation) {
                return (
                  <motion.span
                    whileHover={{ translateY: -2 }}
                    style={{ display: "block" }}
                  >
                    <Link
                      to={translation.uri}
                      style={{ color: props.linkColor || colors.mediumBlue }}
                    >
                      {language?.code}
                    </Link>
                  </motion.span>
                );
              }

              return <span style={{ opacity: 0.3 }}>{language?.code}</span>;
            })()}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
