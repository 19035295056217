import React, { FC } from "react";

export const Logo: FC = () => (
  <svg viewBox="0 0 121.73 99.541" height={"100%"}>
    <g fill="currentColor">
      <path d="M7.428 40.341a1.916 1.916 0 012.139-1.828c2.061 0 2.878 1.478 3.423 2.683l5.521-2.528a8.944 8.944 0 00-8.789-6.3c-5.406 0-8.945 3.189-8.945 8.089 0 8.711 11.278 8.4 11.278 12.017 0 1.205-.817 1.906-2.411 1.906a4.784 4.784 0 01-4.083-3.032L0 54.109a9.923 9.923 0 003.694 4.887h.18a9.014 9.014 0 005.348-1.609 13.053 13.053 0 017.518-2.168c.515 0 .994.022 1.445.061a9.133 9.133 0 00.6-3.313c-.001-8.319-11.357-7.932-11.357-11.626z" />
      <path d="M8.206 11.434l10.111 16.683h6.609V.894h-6.531v16.878L8.439.894H1.672v27.223h6.534z" />
      <path d="M39.164 28.623c6.456 0 11.2-4.589 11.2-10.733 0-6.184-4.628-10.7-11.2-10.7-6.3 0-11.084 4.589-11.084 10.773 0 6.149 4.589 10.66 11.084 10.66zm0-15.711a4.673 4.673 0 014.706 4.978 4.728 4.728 0 01-4.706 5.017c-2.645 0-4.589-1.983-4.589-4.939 0-2.995 1.906-5.056 4.589-5.056z" />
      <path d="M64.793 7.194a5.748 5.748 0 00-5.406 3.228V7.7h-6.339v20.417h6.495v-9.022c0-3.072 1.05-5.367 4.2-5.367a6.843 6.843 0 011.05.078z" />
      <path d="M69.11 20.145c0 6.067 2.8 8.284 7.622 8.284a10.441 10.441 0 002.489-.234v-5.56c-.427.039-.739.039-1.089.039-1.322 0-2.528-.428-2.528-2.216v-7.507h3.461V7.7h-3.461V3.384h-6.495V7.7h-1.983v5.251h1.983z" />
      <path d="M88.751 15.945a2.765 2.765 0 012.878-2.917 2.6 2.6 0 012.683 2.917v12.173h6.495V16.803c0-4.162-.622-9.607-7-9.607a5.963 5.963 0 00-5.056 2.606V.003h-6.495v28.115h6.495z" />
      <path d="M8.4 81.263h6.689v-5.912H8.4v-4.55h7.856v-5.95H1.748v27.224h14.82v-5.95H8.4z" />
      <path d="M31.54 71.151a6.118 6.118 0 00-5.134 2.606v-2.1h-6.34v20.418h6.5V79.902a2.7 2.7 0 012.8-2.917 2.6 2.6 0 012.684 2.917v12.173h6.495V80.758c-.004-4.162-.627-9.607-7.005-9.607z" />
      <path d="M51.142 71.151c-5.989 0-10.034 4.784-10.034 10.773 0 6.028 4.123 10.656 10.851 10.656 5.289 0 8.09-3.305 8.673-4.511l-4.278-3.305a5.267 5.267 0 01-4.278 2.178c-2.411 0-4.161-1.555-4.317-3.384h13.379c.039-.467.039-.895.039-1.283-.005-6.962-3.54-11.124-10.035-11.124zm-3.189 8.284a3.231 3.231 0 013.306-3.033 3.132 3.132 0 013.306 3.033z" />
      <path d="M70.121 74.379v-2.723h-6.339v20.418h6.495v-9.023c0-3.072 1.05-5.367 4.2-5.367a6.865 6.865 0 011.05.078v-6.611a5.748 5.748 0 00-5.406 3.228z" />
      <path d="M92.604 73.251a7.688 7.688 0 00-5.523-2.1c-5.872 0-10 4.434-10 10.7 0 6.339 3.734 10.733 9.645 10.733a7.541 7.541 0 005.445-2.061v.506c0 2.489-2.411 3.033-4.822 3.033a14.2 14.2 0 01-5.639-1.244l-1.906 4.974a17.55 17.55 0 007.545 1.75c6.067 0 11.317-2.372 11.317-9.917V71.657h-6.062zm-4.628 13.613c-2.645 0-4.395-2.061-4.395-5.056 0-3.033 1.75-4.939 4.356-4.939 2.8 0 4.434 2.216 4.434 4.939-.005 3.227-1.833 5.055-4.399 5.055z" />
      <path d="M114.963 71.656l-3.773 11.745-3.617-11.745h-6.961l7.623 19.757-2.762 7.622h6.145l10.112-27.379z" />
      <path d="M30.802 39.173c-5.989 0-10.034 4.784-10.034 10.773a10.917 10.917 0 002.124 6.674c.487.251.934.514 1.367.768a9.693 9.693 0 0010.7 0 13.682 13.682 0 014.561-1.894l-3.5-2.708a5.268 5.268 0 01-4.278 2.177c-2.411 0-4.161-1.555-4.317-3.384h13.379c.039-.467.039-.895.039-1.283-.007-6.961-3.546-11.123-10.041-11.123zm-3.189 8.284a3.231 3.231 0 013.305-3.033 3.131 3.131 0 013.306 3.033z" />
      <path d="M50.287 46.989c-4.822 0-7.934 2.683-7.934 6.767a7.444 7.444 0 00.145 1.463 13.051 13.051 0 017.5 2.167 9.06 9.06 0 004.568 1.584c.245.015.5.024.774.024a9.017 9.017 0 005.35-1.61l.019-.011v-9.919c0-4.667-1.789-8.284-8.828-8.284a14.869 14.869 0 00-8.323 2.528l2.411 4.356a11.785 11.785 0 015.133-1.284c2.1 0 3.112.972 3.112 2.217v.739a10.406 10.406 0 00-3.927-.737zm4.045 6.533c0 1.362-1.167 2.1-2.722 2.1-1.517 0-2.761-.739-2.761-2.1 0-1.322 1.167-2.177 2.722-2.177s2.76.779 2.76 2.179z" />
    </g>
  </svg>
);
