import React, { useState, useEffect, FC, useContext } from "react";
import { motion, useViewportScroll } from "framer-motion";
import Headroom from "react-headroom";
import { spaceRem, fontStacks, colors } from "../global-style";
import { Logo } from "./logo";
import { LanguageSwitch } from "./language-switch";
import { useStaticQuery, graphql, Link } from "gatsby";
import { NavbarWideQuery } from "../../graphql-types";
import { useLocation } from "@reach/router";
import { LayoutContext } from "./layout";

export const NavbarWide: FC = () => {
  const data = useStaticQuery<NavbarWideQuery>(graphql`
    fragment ConnectedNode on WpMenuItem {
      connectedNode {
        node {
          uri
        }
      }
    }

    fragment MenuItem on WpMenuItem {
      label
      ...ConnectedNode
      childItems {
        nodes {
          label
          ...ConnectedNode
        }
      }
    }

    query NavbarWide {
      nlPrimary: allWpMenuItem(
        filter: {
          locations: { eq: PRIMARY_NAVIGATION }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      enPrimary: allWpMenuItem(
        filter: {
          locations: { eq: PRIMARY_NAVIGATION___EN }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      nlSecondary: allWpMenuItem(
        filter: {
          locations: { eq: SECONDARY_NAVIGATION }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      enSecondary: allWpMenuItem(
        filter: {
          locations: { eq: SECONDARY_NAVIGATION___EN }
          parentId: { eq: null }
        }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }
    }
  `);

  const { setSearchIsVisible } = useContext(LayoutContext);

  const location = useLocation();
  const currentLanguage = location.pathname.split("/").filter(v => v)[0] as
    | "nl"
    | "en";
  const primaryMenuItems =
    data[`${currentLanguage}Primary` as "nlPrimary" | "enPrimary"]?.edges;
  const secondaryMenuItems =
    data[`${currentLanguage}Secondary` as "nlSecondary" | "enSecondary"]?.edges;

  const [navbarIsCompact, setNavbarIsCompact] = useState(false);

  const { scrollY } = useViewportScroll();

  useEffect(() => {
    setNavbarIsCompact(typeof window !== "undefined" && window.scrollY > 20);

    scrollY.onChange(v => {
      setNavbarIsCompact(v > 20);
    });
  }, []);

  return (
    <Headroom
      wrapperStyle={{ zIndex: 999, position: "fixed", width: "100%" }}
      pinStart={150}
    >
      <motion.div
        initial={false}
        animate={{
          y: navbarIsCompact ? `${-0.75 * spaceRem}rem` : 0,
        }}
        style={{
          boxShadow: navbarIsCompact ? "0 5px 15px rgba(0,0,0,0.05)" : "none",
          backgroundColor: navbarIsCompact ? colors.white : "transparent",
          paddingLeft: `${1.5 * spaceRem}rem`,
          paddingRight: `${1.5 * spaceRem}rem`,
          paddingTop: `${1 * spaceRem}rem`,
          paddingBottom: `${0.25 * spaceRem}rem`,
        }}
      >
        <motion.div
          initial={false}
          animate={{
            color: navbarIsCompact ? colors.darkestBlue : colors.white,
          }}
          css={`
            max-width: 100rem;
            margin-right: auto;
            margin-left: auto;
            display: flex;

            > * {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              white-space: nowrap;
            }

            > *:first-child > * {
              margin-right: auto;
            }

            > *:last-child > * {
              margin-left: auto;
            }
          `}
        >
          <div>
            <motion.div
              initial={false}
              animate={{
                height: navbarIsCompact ? 60 : 100,
                color: navbarIsCompact ? colors.mediumBlue : colors.white,
              }}
            >
              <Link to={`/${currentLanguage}/home/`}>
                <Logo />
              </Link>
            </motion.div>
          </div>

          <div
            style={{
              fontFamily: fontStacks.poppins,
              fontWeight: 700,
            }}
          >
            <nav>
              <ul style={{ display: "flex" }}>
                {primaryMenuItems?.map((edge, i) => (
                  <motion.li
                    key={i}
                    style={{
                      position: "relative",
                      ...(i > 0 && { marginLeft: `${1.5 * spaceRem}rem` }),
                    }}
                    initial="childItemsHidden"
                    whileHover={
                      "childItemsVisible"
                      // TODO: make this work
                      // location.state.referrer === location.pathname
                      //   ? "childItemsHidden"
                      //   : "childItemsVisible"
                    }
                  >
                    <motion.span
                      whileHover={{ translateY: -3 }}
                      style={{ display: "block" }}
                    >
                      <Link
                        to={edge?.node?.connectedNode?.node?.uri}
                        state={{ referrer: location.pathname }}
                      >
                        {edge?.node?.label}
                      </Link>
                    </motion.span>

                    {edge?.node?.childItems?.nodes?.length ? (
                      <motion.div
                        style={{
                          position: "absolute",
                          paddingTop: `${0.25 * spaceRem}rem`,
                          left: `${-1 * spaceRem}rem`,
                        }}
                        variants={{
                          childItemsHidden: {
                            pointerEvents: "none",
                          },
                          childItemsVisible: {
                            pointerEvents: "initial",
                          },
                        }}
                      >
                        <motion.div
                          style={{
                            backgroundColor: colors.white,
                            paddingTop: `${1 * spaceRem}rem`,
                            paddingBottom: `${0.5 * spaceRem}rem`,
                            paddingLeft: `${1 * spaceRem}rem`,
                            paddingRight: `${1 * spaceRem}rem`,
                            transformOrigin: "top left",
                            filter:
                              "drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.05))",
                          }}
                          variants={{
                            childItemsHidden: {
                              opacity: 0,
                              transform: `skew(2deg)`,
                            },
                            childItemsVisible: {
                              opacity: 1,
                              transform: "skew(0deg)",
                            },
                          }}
                        >
                          <svg
                            viewBox="0 0 235 70"
                            preserveAspectRatio="none"
                            style={{
                              position: "absolute",
                              left: 0,
                              top: `calc(100% - ${spaceRem}rem)`,
                              width: "100%",
                              height: `${2 * spaceRem}rem`,
                            }}
                          >
                            <defs>
                              <clipPath id="navbar_wide_wave__a">
                                <path fill={colors.white} d="M0 0h235v70H0z" />
                              </clipPath>
                              <clipPath id="navbar_wide_wave__b">
                                <path fill={colors.white} d="M0 0h235v93H0z" />
                              </clipPath>
                            </defs>
                            <g>
                              <g clipPath="url(#navbar_wide_wave__a)">
                                <g
                                  transform="translate(0 -22)"
                                  clipPath="url(#navbar_wide_wave__b)"
                                >
                                  <g>
                                    <path
                                      d="M237.463 65.319C157.115 42.51 79.39 101.295-.93 79.392Q-2.995 35.604-5.066-8.193c80.344 22.808 158.074-35.979 238.39-14.073q2.073 43.788 4.139 87.585z"
                                      fill={colors.white}
                                    />
                                  </g>
                                </g>
                                <path fill={colors.white} d="M0-29h235V5H0z" />
                              </g>
                            </g>
                          </svg>

                          <ul
                            style={{
                              position: "relative",
                              color: colors.mediumBlue,
                              fontWeight: 600,
                            }}
                          >
                            {edge.node.childItems.nodes.map((node, i) => (
                              <li key={i}>
                                <Link
                                  to={node?.connectedNode?.node?.uri}
                                  style={{
                                    display: "block",
                                    paddingTop: `${0.25 * spaceRem}rem`,
                                    paddingBottom: `${0.25 * spaceRem}rem`,
                                  }}
                                  state={{ referrer: location.pathname }}
                                >
                                  <motion.span
                                    whileHover={{ translateX: 3 }}
                                    style={{ display: "block" }}
                                  >
                                    {node?.label}
                                  </motion.span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                      </motion.div>
                    ) : null}
                  </motion.li>
                ))}
              </ul>
            </nav>
          </div>

          <div style={{ marginLeft: `${spaceRem}rem` }}>
            <nav
              style={{ display: "flex", alignItems: "center" }}
              css={`
                > * + * {
                  margin-left: ${spaceRem}rem;
                }
              `}
            >
              <ul style={{ display: "flex" }}>
                {secondaryMenuItems?.map((edge, i) => (
                  <motion.li
                    key={i}
                    style={{
                      position: "relative",
                      ...(i > 0 && { marginLeft: `${1 * spaceRem}rem` }),
                    }}
                  >
                    <motion.span
                      whileHover={{ translateY: -3 }}
                      style={{ display: "block" }}
                    >
                      <Link to={edge?.node?.connectedNode?.node?.uri}>
                        {edge?.node?.label}
                      </Link>
                    </motion.span>
                  </motion.li>
                ))}
              </ul>
              <div style={{ position: "relative", top: 2 }}>
                <LanguageSwitch
                  linkColor={navbarIsCompact ? colors.mediumBlue : colors.white}
                />
              </div>
              <div
                style={{ flexShrink: 0, width: "1rem", cursor: "pointer" }}
                onClick={() => setSearchIsVisible(true)}
              >
                <svg viewBox="0 0 21.707 21.707">
                  <g fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M15.58 15.581L20.999 21" />
                    <g>
                      <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
                      <circle cx="9.5" cy="9.5" r="8.5" />
                    </g>
                  </g>
                </svg>
              </div>
            </nav>
          </div>
        </motion.div>
      </motion.div>
    </Headroom>
  );
};
