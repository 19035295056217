import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import React, { FC, createContext, useState, useEffect, useRef } from "react";
import ReactResizeDetector from "react-resize-detector";
import {
  GlobalStyle,
  targetBaseFontSizePx,
  colors,
  spaceRem,
  fontStacks,
} from "../global-style";
import { Helmet } from "react-helmet";
import { NavbarWide } from "./navbar-wide";
import { NavbarNarrow } from "./navbar-narrow";
import { useStaticQuery, graphql, Link } from "gatsby";
import { LayoutQuery } from "../../graphql-types";
import { FluidObject } from "gatsby-image";
import Img from "gatsby-image";
import { useLocation } from "@reach/router";
import { css } from "styled-components";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Search } from "./search";
import CookieConsent from "react-cookie-consent";
import Popup from "./popup";

type LayoutContext = {
  remInPx: number;
  vwInPx?: number;
  searchIsVisible: boolean;
  setSearchIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export const LayoutContext = createContext<LayoutContext>({} as LayoutContext);

const Layout: FC<{ pageContext: { id?: string } }> = props => {
  const data = useStaticQuery<LayoutQuery>(graphql`
    query Layout {
      footer: file(relativePath: { eq: "footer.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      nlFooterNavigation: allWpMenuItem(
        filter: { locations: { eq: FOOTER_NAVIGATION } }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }

      enFooterNavigation: allWpMenuItem(
        filter: { locations: { eq: FOOTER_NAVIGATION___EN } }
      ) {
        edges {
          node {
            ...MenuItem
          }
        }
      }
    }
  `);

  const [searchIsVisible, setSearchIsVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const location = useLocation();
  let detectedLanguage = location.pathname.split("/").filter(v => v)[0];
  const currentLanguage =
    detectedLanguage === "nl" || detectedLanguage === "en"
      ? detectedLanguage
      : "en";

  const cookieText = {
    en: {
      buttonText: "Accept",
      declineButtonText: "Decline",
      message:
        "This website uses cookies to improve your experience and optimize our services.",
    },
    nl: {
      buttonText: "Accepteren",
      declineButtonText: "Afwijzen",
      message:
        "Deze website maakt gebruik van cookies om uw ervaring te verbeteren en onze diensten te optimaliseren.",
    },
  };

  const currentCookieText = cookieText[currentLanguage];

  const footerMenuItems =
    data[
      `${currentLanguage}FooterNavigation` as
        | "nlFooterNavigation"
        | "enFooterNavigation"
    ]?.edges;

  const emailEl = useRef<HTMLInputElement>(null);

  return (
    <>
      <GlobalStyle />

      <Helmet
        htmlAttributes={{
          lang: currentLanguage,
        }}
        title="North Sea Energy"
      />

      <ReactResizeDetector
        refreshMode="debounce"
        refreshRate={250}
        handleWidth={true}
        render={({ width: vwInPx }) => {
          const remInPx =
            typeof window !== "undefined"
              ? parseFloat(
                  window.getComputedStyle(document.documentElement).fontSize
                )
              : targetBaseFontSizePx;

          return (
            <LayoutContext.Provider
              value={{
                remInPx,
                vwInPx,
                searchIsVisible,
                setSearchIsVisible,
              }}
            >
              <Search />

              <div
                css={`
                  @media (min-width: 1200px) {
                    display: none;
                  }
                `}
              >
                <NavbarNarrow />
              </div>

              <div
                css={`
                  display: none;

                  @media (min-width: 1200px) {
                    display: block;
                  }
                `}
              >
                <NavbarWide />
              </div>

              <main>{props.children}</main>

              <footer
                style={{
                  backgroundColor: colors.darkestBlue,
                  position: "relative",
                  paddingTop: `${2.5 * spaceRem}rem`,
                }}
              >
                <Img
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                  }}
                  fluid={data.footer?.childImageSharp?.fluid as FluidObject}
                />

                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: `${0.5 * spaceRem}rem`,
                    paddingRight: `${0.5 * spaceRem}rem`,
                  }}
                >
                  <h3
                    style={{
                      textTransform: "uppercase",
                      fontSize: "0.65rem",
                      fontWeight: 600,
                      color: colors.mediumBlue,
                      letterSpacing: 2,
                      marginBottom: `${0.25 * spaceRem}rem`,
                    }}
                  >
                    {(currentLanguage === "en" && "Newsletter") ||
                      (currentLanguage === "nl" && "Nieuwsbrief")}
                  </h3>
                  <h2
                    style={{
                      color: colors.white,
                      marginBottom: `${1 * spaceRem}rem`,
                    }}
                    css={(() => {
                      const minFontSizeRem = 1.5;
                      const maxFontSizeRem = 2.25;
                      const vwRangeStartPx = 400;

                      return css`
                        font-size: ${(minFontSizeRem *
                          (remInPx || targetBaseFontSizePx)) /
                          (vwRangeStartPx / 100)}vw;

                        @media (max-width: ${vwRangeStartPx}px) {
                          font-size: ${minFontSizeRem}rem;
                        }

                        @media (min-width: ${vwRangeStartPx *
                            (maxFontSizeRem / minFontSizeRem)}px) {
                          font-size: ${maxFontSizeRem}rem;
                        }
                      `;
                    })()}
                  >
                    {(currentLanguage === "en" && "Stay up to date") ||
                      (currentLanguage === "nl" && "Updates in je inbox")}
                  </h2>
                  <div
                    style={{
                      marginBottom: `${2 * spaceRem}rem`,
                    }}
                  >
                    <a
                      href="https://msg-sustainable-strategies.email-provider.eu/memberforms/subscribe/standalone/form/?a=rq4ucopfol&l=by4ogsvw0h"
                      target="_blank"
                      className="button"
                      style={{
                        display: "block",
                        margin: `${0.25 * spaceRem}rem`,
                        flexGrow: 1,
                      }}
                    >
                      {(currentLanguage === "en" && "Subscribe") ||
                        (currentLanguage === "nl" && "Aanmelden")}
                    </a>
                  </div>
                  {/* 
                  <MailchimpSubscribe
                    url="https://north-sea-energy.us19.list-manage.com/subscribe/post?u=8f7514ad5cc3b89037ea25f31&amp;id=9a21bd59c4"
                    render={({ message, status, subscribe }) => (
                      <>
                        <div
                          style={{
                            marginBottom: `${2 * spaceRem}rem`,
                          }}
                        >
                          <a
                            href="https://msg-sustainable-strategies.email-provider.eu/memberforms/subscribe/standalone/form/?a=rq4ucopfol&l=by4ogsvw0h"
                            target="_blank"
                            className="button"
                            style={{
                              display: "block",
                              margin: `${0.25 * spaceRem}rem`,
                              flexGrow: 1,
                            }}
                          >
                            Aanmelden
                          </a>
                          {status !== "sending" && status !== "success" && (
                            <div
                              style={{
                                flexWrap: "wrap",
                                margin: `${-0.25 * spaceRem}rem`,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  margin: `${0.25 * spaceRem}rem`,
                                  border: `1px solid ${colors.darkBlue}`,
                                  display: "flex",
                                  alignItems: "center",
                                  flexGrow: 1,
                                }}
                              >
                                <svg
                                  width={14.737}
                                  height={14.737}
                                  viewBox="0 0 14.737 14.737"
                                  style={{
                                    marginLeft: `${0.5 * spaceRem}rem`,
                                    flexShrink: 0,
                                  }}
                                >
                                  <path
                                    d="M9.982 1.663C9.392 1.194 8.4.2 7.368.2s-2 .974-2.613 1.463A498.28 498.28 0 00.693 4.94 1.344 1.344 0 00.2 5.98v7.213a1.344 1.344 0 001.344 1.344h11.649a1.344 1.344 0 001.344-1.344V5.98a1.344 1.344 0 00-.493-1.04 498.28 498.28 0 00-4.062-3.277zm3.211 11.977H1.544a.448.448 0 01-.444-.447v-7.22a.448.448 0 01.165-.347c.736-.6 3.41-2.751 4.052-3.261C5.806 1.972 6.705 1.1 7.368 1.1s1.562.876 2.056 1.269c.642.51 3.316 2.66 4.052 3.261a.448.448 0 01.165.347v7.219a.448.448 0 01-.448.448zm-.245-6.057a.336.336 0 01-.05.473c-.639.516-1.544 1.239-2.917 2.328-.589.47-1.592 1.471-2.613 1.463s-2.039-1.005-2.613-1.463a338.17 338.17 0 01-2.916-2.327.336.336 0 01-.05-.473l.141-.174a.336.336 0 01.47-.05c.637.515 1.54 1.236 2.91 2.324.472.376 1.406 1.279 2.056 1.269.65.01 1.584-.893 2.056-1.269a301.022 301.022 0 002.913-2.324.336.336 0 01.472.05z"
                                    fill={colors.mediumBlue}
                                    stroke={colors.mediumBlue}
                                    strokeWidth={0.4}
                                  />
                                </svg>

                                <input
                                  ref={emailEl}
                                  type="text"
                                  style={{
                                    marginLeft: `${0.5 * spaceRem}rem`,
                                    backgroundColor: "transparent",
                                    border: "none",
                                    outline: "none",
                                    color: colors.white,
                                    fontSize: "1rem",
                                    paddingTop: `${0.25 * spaceRem}rem`,
                                    paddingBottom: `${0.25 * spaceRem}rem`,
                                    paddingRight: `${0.5 * spaceRem}rem`,
                                  }}
                                  placeholder={
                                    (currentLanguage === "en" &&
                                      "Your e-mail address") ||
                                    (currentLanguage === "nl" &&
                                      "Je e-mailadres") ||
                                    ""
                                  }
                                />
                              </div>

                              <button
                                onClick={() => {
                                  if (emailEl.current) {
                                    subscribe({ EMAIL: emailEl.current.value });
                                  }
                                }}
                                style={{
                                  margin: `${0.25 * spaceRem}rem`,
                                  flexGrow: 1,
                                }}
                              >
                                Aanmelden
                              </button>
                            </div>
                          )}

                          <div style={{ marginTop: `${0.5 * spaceRem}rem` }}>
                            {status === "sending" && (
                              <p style={{ color: colors.darkBlue }}>
                                {(currentLanguage === "en" &&
                                  "One moment please...") ||
                                  (currentLanguage === "nl" &&
                                    "Een moment geduld..") ||
                                  ""}
                              </p>
                            )}
                            {status === "error" && (
                              <p
                                style={{ color: colors.lightRed }}
                                dangerouslySetInnerHTML={{
                                  __html: message || "",
                                }}
                              />
                            )}
                            {status === "success" && (
                              <p
                                style={{ color: colors.mediumBlue }}
                                dangerouslySetInnerHTML={{
                                  __html: message || "",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  /> */}

                  <ul
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      marginBottom: `${spaceRem}rem`,
                      color: colors.darkBlue,
                      fontSize: "0.65rem",
                      textTransform: "uppercase",
                      fontFamily: fontStacks.poppins,
                      fontWeight: 600,
                      letterSpacing: 2,
                      justifyContent: "center",
                    }}
                    css={`
                      li {
                        padding: ${0.25 * spaceRem}rem ${0.5 * spaceRem}rem;
                      }
                    `}
                  >
                    {footerMenuItems?.map((edge, i) => (
                      <li key={i}>
                        <Link to={edge?.node?.connectedNode?.node?.uri}>
                          {edge?.node?.label}
                        </Link>
                      </li>
                    ))}

                    <li style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flexShrink: 0 }}>
                        <a
                          href="https://twitter.com/NorthSeaEnergy_"
                          target="_blank"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: colors.darkBlue,
                            padding: `${0.5 * spaceRem}rem`,
                            borderRadius: "50%",
                          }}
                        >
                          <svg
                            width={13.088}
                            height={10.63}
                            viewBox="0 0 13.088 10.63"
                          >
                            <path
                              d="M11.743 2.649c.008.116.008.233.008.349a7.58 7.58 0 01-7.632 7.632A7.58 7.58 0 010 9.426a5.548 5.548 0 00.648.033 5.372 5.372 0 003.33-1.146 2.687 2.687 0 01-2.508-1.86 3.383 3.383 0 00.507.042 2.837 2.837 0 00.706-.091A2.683 2.683 0 01.531 3.77v-.033a2.7 2.7 0 001.212.34A2.686 2.686 0 01.913.489a7.625 7.625 0 005.531 2.807 3.028 3.028 0 01-.066-.615A2.685 2.685 0 0111.02.846a5.281 5.281 0 001.7-.648 2.675 2.675 0 01-1.179 1.478 5.377 5.377 0 001.545-.415 5.766 5.766 0 01-1.343 1.388z"
                              fill="#011c34"
                            />
                          </svg>
                        </a>
                      </div>

                      <div
                        style={{
                          flexShrink: 0,
                          marginLeft: `${0.25 * spaceRem}rem`,
                        }}
                      >
                        <a
                          href="https://nl.linkedin.com/company/north-sea-energy"
                          target="_blank"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: colors.darkBlue,
                            padding: `${0.5 * spaceRem}rem`,
                            borderRadius: "50%",
                          }}
                        >
                          <svg
                            width={10.63}
                            height={10.63}
                            viewBox="0 0 10.63 10.63"
                          >
                            <path
                              d="M2.379 10.63H.176v-7.1h2.2zm-1.1-8.065a1.282 1.282 0 111.274-1.289 1.287 1.287 0 01-1.277 1.289zm9.351 8.065h-2.2V7.175c0-.823-.017-1.879-1.146-1.879-1.146 0-1.321.895-1.321 1.82v3.514H3.76v-7.1h2.114v.968H5.9a2.316 2.316 0 012.09-1.143c2.23 0 2.64 1.469 2.64 3.376v3.9z"
                              fill="#011c34"
                            />
                          </svg>
                        </a>
                      </div>
                    </li>

                    <li>
                      <a href="https://www.webba.nl/" target="_blank">
                        Website: Webba
                      </a>
                    </li>
                  </ul>
                </div>
                {showPopup && (
                  <Popup
                    onClose={handleClosePopup}
                    currentLanguage={currentLanguage}
                    remInPx={remInPx}
                  />
                )}
              </footer>

              <CookieConsent
                style={{ background: "#011c34" }}
                buttonStyle={{ background: "#73c4d7" }}
                location="bottom"
                buttonText={currentCookieText.buttonText}
                declineButtonText={currentCookieText.declineButtonText}
                cookieName="gatsby-gdpr-google-tagmanager"
              >
                {currentCookieText.message}
              </CookieConsent>
            </LayoutContext.Provider>
          );
        }}
      />
    </>
  );
};

export default Layout;
