import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { Index } from "elasticlunr";
import { colors } from "../global-style";
import { LayoutContext } from "./layout";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

export const Search: FC = () => {
  const data = useStaticQuery(graphql`
    query Search {
      siteSearchIndex {
        index
      }
    }
  `);

  const inputEl = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const currentLanguageCode = (location.pathname.split("/").filter(v => v)[0] || "").toUpperCase() as "NL" | "EN";

  useEffect(() => {
    setSearchIsVisible(false);
    setQuery("");
  }, [location]);

  const { searchIsVisible, setSearchIsVisible } = useContext(LayoutContext);
  const index = useRef(Index.load(data.siteSearchIndex.index));
  const [query, setQuery] = useState("");
  const results = (query.length > 2
    ? index?.current
      ?.search(query, { expand: true })
      .map(({ ref }) => {
        return index?.current?.documentStore.getDoc(ref);
      })
      .filter((result: any) => result.languageCode === currentLanguageCode)
    : []) as {
      id: string;
      date: string;
      title: string;
      content: string;
      path: string;
      languageCode: "NL" | "EN";
      type: "page" | "post" | "event" | "programmeItem";
    }[];

  useEffect(() => {
    if (searchIsVisible) {
      inputEl.current?.focus();
    }
  }, [searchIsVisible]);

  const types = {
    NL: {
      page: "pagina",
      post: "nieuwsbericht",
      event: "evenement",
      programmeItem: "programma-item",
    },
    EN: {
      page: "page",
      post: "news post",
      event: "event",
      programmeItem: "programme item",
    },
  };

  return (
    <div
      style={{
        opacity: searchIsVisible ? 1 : 0,
        zIndex: searchIsVisible ? 99999 : -99999,
        transition: "all 0.2s ease-in-out",
        position: "fixed",
        backgroundColor: "#1a354d",
        color: colors.white,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <button
        style={{
          alignSelf: "flex-end",
          marginBottom: results.length ? "2rem" : 0,
        }}
        onClick={() => {
          setSearchIsVisible(false);
        }}
      >
        sluiten
      </button>

      <AnimateSharedLayout>
        <motion.div
          layout
          style={{
            alignSelf: "center",
            marginTop: results.length ? 0 : "auto",
            marginBottom: results.length ? 0 : "auto",
            width: "100%",
            maxWidth: "60rem",
          }}
        >
          <h1 style={{ marginBottom: "1rem" }}>
            {currentLanguageCode === "NL" ? "Zoeken" : "Search"}
          </h1>

          <input
            ref={inputEl}
            css={`
              border: none;
              border-bottom: 1px solid rgba(255, 255, 255, 0.6);

              ::placeholder {
                color: rgba(255, 255, 255, 0.6);
              }

              :focus {
                outline: none;
                border-bottom: 1px solid #fff;
              }
            `}
            placeholder={
              currentLanguageCode === "NL"
                ? "Typ hier je zoekterm"
                : "Enter your search query here"
            }
            style={{
              transition: "all 0.2s ease-in-out",
              width: "100%",
              backgroundColor: "transparent",
              color: "#fff",
              paddingBottom: "0.5rem",
              fontSize: "1rem",
            }}
            value={query}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />
        </motion.div>

        <AnimatePresence>
          {results.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.25 } }}
              exit={{ opacity: 0 }}
              style={{
                marginTop: "3rem",
                alignSelf: "center",
                maxWidth: "60rem",
                width: "100%",
                overflowY: "hidden",
              }}
            >
              <h2 style={{ marginBottom: "1rem" }}>Resultaten</h2>
              <ul
                css={`
                  > * + * {
                    margin-top: 1.5rem;
                  }
                `}
                style={{
                  overflowY: "auto",
                  height: "100%",
                  width: "100%",
                  maxWidth: "none",
                  paddingBottom: "5rem",
                }}
              >
                {results.map(item => (
                  <li key={item.id}>
                    <Link
                      to={item.path}
                      css={`
                        :hover {
                          text-decoration: underline;
                        }
                      `}
                    >
                      {item.title}
                    </Link>
                    <p style={{ fontSize: "0.8rem", opacity: 0.6 }}>
                      {currentLanguageCode === "NL" ? "soort" : "type"}:{" "}
                      {types[currentLanguageCode][item.type]}
                    </p>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
};
