import { createGlobalStyle, css } from "styled-components";

/**
 * Colors
 */

export const colors = {
  white: "#ffffff",
  mediumBlue: "#73c4d7",
  lightBlue: "#d9f6ff",
  lightestBlue: "#f4fdff",
  darkBlue: "#526575",
  darkestBlue: "#011c34",
  lightRed: "#ff6666",
};

/**
 * Typography
 */

export const targetBaseFontSizePx = 18;
const targetBaseLineHeightPx = 32;

const defaultBaseFontSizePx = 16;
export const baseFontSizeRem = targetBaseFontSizePx / defaultBaseFontSizePx;
export const baseLineHeightRem = targetBaseLineHeightPx / targetBaseFontSizePx;

export const fontStacks = {
  roboto: "Roboto, sans-serif",
  poppins: "Poppins, sans-serif",
};

export const measure = "80ch";

export const narrowCenterRem = 46;
export const wideCenterRem = 66;

/**
 * Grid spacing
 */

export const spaceRem = baseLineHeightRem;

/**
 * Global styles
 */

export const GlobalStyle = createGlobalStyle`${css`
  /**
   * Use a better box model
   */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /**
   * Reset paddings and margins
   */

  * {
    margin: 0;
    padding: 0;
  }

  /**
   * Set base text style
   */

  :root {
    font-family: ${fontStacks.roboto};
    font-weight: 500;
    font-style: normal;
    font-size: ${baseFontSizeRem}rem;
    color: ${colors.darkestBlue};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /**
   * Set base line height
   */

  body {
    line-height: ${baseLineHeightRem}rem;
  }

  /**
   * Set heading text style
   */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${fontStacks.poppins};
    font-weight: 700;
    line-height: 1.25;
  }

  /**
   * Hyphenate long words 
   */

  * {
    hyphens: auto;
  }

  /**
   * Set measure
   */

  * {
    max-width: ${measure};
  }

  html,
  body,
  div,
  header,
  nav,
  main,
  footer,
  section,
  iframe,
  svg,
  img,
  input,
  canvas,
  video,
  button {
    max-width: none;
  }

  /**
   * Reset list styles
   */

  ul,
  ol,
  dd {
    list-style: none;
  }

  /**
   * Set image styles
   */

  img {
    display: block;
    max-width: 100%;
  }

  /**
   * Set SVG styles
   */

  svg {
    display: block;
  }

  /**
   * Reset link styles
   */

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /**
   * Hide horizontal overflow
   */

  #___gatsby {
    overflow-x: hidden;
  }

  /**
   * Set button base style
   */

  button,
  .button {
    color: ${colors.white};
    background-color: ${colors.mediumBlue};
    border: none;
    font-family: ${fontStacks.poppins};
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 2px;
    padding: ${0.4 * spaceRem}rem ${0.5 * spaceRem}rem;
    cursor: pointer;
    position: relative;
    transform: translateY(0);
    transition: all 0.25s ease;
  }

  button:hover,
  .button:hover {
    transform: translateY(-2px);
  }

  .popup {
    position: fixed;
    bottom: 1.5rem;
    left: 1.5rem;
    z-index: 100;
    background-color: ${colors.darkestBlue};
    padding: 4rem 2rem 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid ${colors.mediumBlue};
  }

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: ${colors.white};
    background-color: transparent;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
  }

  .close-btn:hover {
    transform: scale(1.1);
  }
`}`;
